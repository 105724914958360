import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
   lang = localStorage.getItem('language');
  constructor(public translate: TranslateService) { }

  ngOnInit() {
  }


  setLanguage(language: string){
    this.translate.setDefaultLang(language);
    this.lang = language;
    localStorage.setItem('language', language);
  }



}
