import { Component, ViewContainerRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>'
})
export class AppComponent {

  constructor(public translate: TranslateService) {
     if (localStorage.getItem('language')) {
      this.translate.setDefaultLang(localStorage.getItem('language'));

     } else {
      var defaul: string = 'fr';

      if (this.translate.getBrowserLang().toLocaleLowerCase() !== 'fr') {
        defaul = 'en';
      }
        this.translate.setDefaultLang(defaul);
        localStorage.setItem('language', defaul);
      // this.openLanguage();
     }
  }



  closeLanguage(){

  }


  setLanguage(language: string){
    this.translate.use(language);
    this.closeLanguage();
  }
}
