import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent implements OnInit {
  config: any;
  projects = [
    {
      id: 6,
      title: 'Siedesk',
      desc: 'Saas de création de base de connaissance, pages FAQS et centre d\'aide assisté par IA',
      livedemo: 'https://siedesk.com/',
      githurl: '',
      mediumlink: '',
      imgUrl: 'assets/porfolio/siedesk.png',
      tech: 'Angular, Nestjs, TailwindCSS, Postgresql, Ubuntu, Nginx, Ionos Cloud'
    },
    {
      id: 5,
      title: 'Gimios',
      desc: 'Outil de recherche automatique des missions pour les Freelances',
      livedemo: 'https://gimios.com/',
      githurl: '',
      mediumlink: '',
      imgUrl: 'assets/porfolio/gimios.png',
      tech: 'Nestjs, Angular, Material Design, Postgresql, Ionos Cloud'
    },
    {
      id: 4,
      title: 'Simplanter',
      desc: "Plateforme d'annonce immobiliere pour les entreprises",
      livedemo: 'https://simplanter.fr/',
      githurl: '',
      mediumlink: '',
      imgUrl: 'assets/porfolio/simplanter.png',
      tech: 'Nestjs, Angular, Material Design, Postgresql, Jelastic Cloud'
    },
    {
      id: 1,
      title: 'Wanteez ',
      desc: 'Plateforme de service à la personne',
      livedemo: 'https://wanteez.fr/',
      githurl: '',
      mediumlink: '',
      imgUrl: 'assets/porfolio/wanteez.png',
      tech: 'Angular 13 , Laravel, MySQL, OVHcloud, NGINX, UBUNTU'
    },

    {
      id: 2,
      title: 'Emaileck',
      desc: "plateforme de vérification de liste d'emails",
      livedemo: 'https://emaileck.com/',
      githurl: '',
      mediumlink: '',
      imgUrl: 'assets/porfolio/emaileck.png',
      tech: 'Angular 11, ExpressJs, SocketIo, MongoDB, Ionos Cloud'
    },
    {
      id: 3,
      title: 'Orange And People ',
      desc: 'Forum (web app) avec une interface sociale',
      livedemo: 'https://orange-eaad6.web.app/',
      githurl: '',
      mediumlink: '',
      imgUrl: 'assets/porfolio/orange.png',
      tech: 'Angular 10 , Firebase, ExpressJs, Google Cloud'
    },

    // {
    //   id: 4,
    //   title: 'Fharik',
    //   desc: 'plateforme de facturation et gestion de finance pour les PME et les indépendants',
    //   livedemo: 'https://fharik.com/',
    //   githurl: '',
    //   mediumlink: '',
    //   imgUrl: 'assets/porfolio/fharik.png',
    //   tech: 'Php, codeignitor, HTML, CSS, Jquery, Mysql, Ionos Cloud'
    // },


    // {
    //   id: 5,
    //   title: 'Taqtaq',
    //   desc: 'plateforme de mise en relation avec les escorts girls',
    //   livedemo: 'https://taqtaq.ch',
    //   githurl: '',
    //   mediumlink: '',
    //   imgUrl: 'assets/porfolio/taqtaq.png',
    //   tech: 'Angular 10, Docker, Node, MongoDB, Express, Cloud Digital Ocean'
    // },

    // {
    //   id: 6,
    //   title: 'Funky Like',
    //   desc: "plateforme de suivi de nombre d'abonnées sur les réseaux sociaux",
    //   livedemo: 'https://funkylike.com/',
    //   githurl: '',
    //   mediumlink: '',
    //   imgUrl: 'assets/porfolio/funkylike.png',
    //   tech: 'Angular 11, PHP, Rest API, Mysql'
    // },


    {
      id: 7,
      title: 'Je Prie',
      desc: 'Un réseau social de prière pour les chrétiens',
      livedemo: 'https://play.google.com/store/apps/details?id=com.jeprie&hl=en_US&gl=US',
      githurl: '',
      mediumlink: '',
      imgUrl: 'assets/porfolio/jeprie.png',
      tech: 'Ionic, Angular, Firebase, Cloud function, Google Cloud'
    },

    {
      id: 8,
      title: 'Dating App',
      desc: "Interface d'application de rencontre",
      livedemo: '#',
      githurl: '',
      mediumlink: '',
      imgUrl: 'assets/porfolio/dating.png',
      tech: 'Ionic, Angular, Firebase, Cloud function, Google Cloud'
    },

    {
      id: 9,
      title: 'Application ecommerce',
      desc: "Interface d'application de vente de vetements",
      livedemo: '',
      githurl: '',
      mediumlink: '',
      imgUrl: 'assets/porfolio/dressing.png',
      tech: 'Ionic, Angular, Firebase, Cloud function, Google Cloud'
    },
  ]
  constructor() { }

  ngOnInit() {
    this.config = {
      itemsPerPage: 6,
      currentPage: 1,
      totalItems: this.projects.length
    };
  }

  pageChanged(event){
    this.config.currentPage = event;
  }
}
